// material-ui
import { Stack, Typography } from '@mui/material';
import logoIcon from 'assets/Logo files/PNGs - SVGs/SVG/Asset 2.svg';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="end" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Typography sx={{ mr: 1 }} variant="caption">
        &copy; All rights reserved
      </Typography>
      <img src={logoIcon} alt="logo" style={{ background: theme.palette.grey[200], width: '60px' }} />
    </Stack>
  );
};

export default Footer;
